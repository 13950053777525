import { StaticImage } from "gatsby-plugin-image"
import * as React from "react"
import { BreakpointProvider } from 'gatsby-plugin-breakpoints';
import Layout from "../components/Layout"
import MobileWrapper from "../components/MobileWrapper"
import { Content, H1, Info, InfoItem, InfoText, P } from "../styles"
import Banner from "../components/Banner";

const defaultQueries = {
  xs: '(max-width: 320px)',
  sm: '(max-width: 720px)',
  md: '(max-width: 1024px)',
  l: '(max-width: 1536px)',
};

const IndexPage = () => {
  return (
    <BreakpointProvider queries={defaultQueries}>
      <Layout>
        <Banner text="Hola! Soy Reme Sanchez, y estoy para ayudarte a mejorar tu salud hormonal" />
        <MobileWrapper>
          <StaticImage
            placeholder="blurred"
            src="../images/home.png"
            alt="home image"
            style={{ maxWidth: '45%', height: 'auto', position: 'absolute', right: 96 }}
          />
        </MobileWrapper>
        <Content>
          <H1 animation>
            Sobre mí
          </H1>
          <P animation>Soy Licenciada en Nutrición de la Universidad Nacional de Córdoba, Argentina.</P>
          <P animation>Me especialice en Salud Hormonal y te puedo ayudar a mejorar desde la nutrición y estilo de vida.</P>
          <P animation>Podemos trabajar juntas en tu endometriosis, adenomiosis, síndrome de ovario poliquístico, síndrome premenstrual, dolor menstrual, miomas e hipotiroidismo.</P>
        </Content>

        <Content>
          <Info>
              <InfoItem>
                <StaticImage
                  placeholder="blurred"
                  src="../images/nutrition.png"
                  alt="nutrition image"
                  style={{ maxWidth: 98 }}
                />
                <InfoText>Tu salud hormonal esta muy relacionada a tus hábitos. Eso te da mucho poder en la búsqueda de mejoría.</InfoText>
              </InfoItem>
              <InfoItem>
                <StaticImage
                  placeholder="blurred"
                  src="../images/dumbbell.png"
                  alt="dumbbell image"
                  style={{ maxWidth: 98 }}
                />
                <InfoText>No todo es alimentación, importa mucho tu actividad física, descanso y gestión del estrés.</InfoText>
              </InfoItem>
              <InfoItem>
                <StaticImage
                  placeholder="blurred"
                  src="../images/plan.png"
                  alt="plan image"
                  style={{ maxWidth: 98 }}
                />
                <InfoText>Enfocándote en sumar alimentos que traigan beneficios a tu salud nutricional e intestinal vas a lograr muchos cambios.</InfoText>
              </InfoItem>
              <InfoItem>
                <StaticImage
                  placeholder="blurred"
                  src="../images/apple.png"
                  alt="apple image"
                  style={{ maxWidth: 98 }}
                />
                <InfoText>Podes mejorar y hasta revertir tus síntomas aprendiendo qué necesita tu cuerpo y cómo podés dárselo.</InfoText>
              </InfoItem>
            </Info>
        </Content>
      </Layout>
    </BreakpointProvider>
  )
}

export default IndexPage
