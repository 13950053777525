import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import * as React from 'react';
import { toast, Toaster } from "react-hot-toast"

const Banner: React.FC<{ text: string }> = ({ text }) => {
    const breakpoints = useBreakpoint();

    React.useEffect(() => {
        toast.success(text, { duration: Number.POSITIVE_INFINITY, icon: '👋' });
    }, [])

    return <Toaster
    position={breakpoints.sm ? "bottom-center" : "top-center"}
    reverseOrder={false}
  />
}

export default Banner;
